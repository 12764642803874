// 页面样式初始化

:root {
  --color-primary: #191d2b;
  --color-secondary: #44b5d8;
  --color-secondary-dark: #0c7797;
  --color-extra: #271f4e;
  --color-white: #f8f8f8;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #f8f8f8;
  --color-grey-2: #f8f8f8;
  --color-grey-3: #f8f8f8;
  --color-grey-4: #454e56;
  --color-grey-5: #2a2e35;
  --color-grey-6: #12181b;
  --br-sm-2: 14px;
  --box-shadow-1: 0 3px 15px rgba(0, 0, 0, 0.3);
}

.light-mode {
  --color-primary: #ffffff;
  --color-secondary: #f56692;
  --color-secondary-dark: #c2d7de;
  --color-white: #454e56;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #6c7983;
  --color-grey-2: #6c7983;
  --color-grey-3: #6c7983;
  --color-grey-4: #454e56;
  --color-grey-5: #f8f8f8;
  --color-grey-6: #12181b;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  font-size: 16px;
  color: var(--color-white);
  background-color: var(--color-primary);
  transition: all 0.2s ease-in-out;
}

section,
canvas {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

td,
th {
  padding: 0;
  font-weight: normal;
  font-style: normal;
}

ul,
ol,
li,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}

a {
  outline: medium none;
  text-decoration: none;
  background: transparent;
}

img {
  border: 0;
  vertical-align: middle;
  font-size: 0;
  max-width: 100%;
}

b,
strong {
  font-weight: normal;
}

i,
em {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: 1.15;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
select {
  margin: 0;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-appearance: button;
  font-size: inherit;
  color: inherit;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input::-ms-clear {
  display: none;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

textarea,
input {
  outline: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

textarea {
  resize: none;
}

pre {
  display: block;
  margin: 0;
  padding: 0;
  font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
